import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";

const RedirectToAppV2 = () => {
  const navigate  = useNavigate();

  const {hash, code} = useParams()
  let qrHost = ''
  let validHash = true;

  const defaultLogo = "https://res.cloudinary.com/dghqjv5az/image/upload/v1669488290/mix/logossala/logo_xd33n7.png"

  if (hash === 'hc3ap') {
    qrHost = 'https://salaqueens.com'
  } else if (hash === '7dkb1') {
    localStorage.setItem("s", "41")
    localStorage.setItem("logo", defaultLogo)
  } else if (hash === 'opq4v') {
    qrHost = 'https://stop.sala.pe'
  } else if (hash === 'ys19n') {
    //qrHost = 'https://angello.sala.pe'
    localStorage.setItem("s", "51")
    localStorage.setItem("logo", defaultLogo)
  }else if (hash === 'hb7qc') {
    //qrHost = 'https://sanmartin3.sala.pe'
    localStorage.setItem("s", "111")
    localStorage.setItem("logo", "https://res.cloudinary.com/dghqjv5az/image/upload/c_fill,h_120/linkedwgt/logo/FaicuInbEpx2RiBkwLuQL0V2rFQWZn0W/Las_vegas.jpg")
  } else if (hash === '8fwj3') {
    //qrHost = 'https://sanmartin3.sala.pe'
    localStorage.setItem("s", "170")
    localStorage.setItem("logo", "https://res.cloudinary.com/dghqjv5az/image/upload/v1669486363/mix/logossala/fargos_promo_jijblw_xqjrto.jpg")
  } else if (hash === '9dan8') {
    localStorage.setItem("s", "192")
    localStorage.setItem("logo", "https://res.cloudinary.com/dghqjv5az/image/upload/c_fill,h_120/linkedwgt/logo/kt9Bs6LSeDDMAlaHn0vhmtec13ITuQ0v/El_dorado.jpg")
  } else if (hash === '8erbr7') {
    localStorage.setItem("s", "194")
    localStorage.setItem("logo", "https://res.cloudinary.com/dghqjv5az/image/upload/c_fill,h_120/linkedwgt/logo/2TseLYyo5wMUwmNvwLFdXQfL9PR1Rzjr/fbfdde92-7ba0-49c5-8a30-e505af69ccfa.jpg")
  } else if (hash === 'y7jn93') {
    localStorage.setItem("s", "124")
    localStorage.setItem("logo", "https://res.cloudinary.com/dghqjv5az/image/upload/c_fill,h_120/linkedwgt/foods/rNWIk2WcOVb4wcdEiZhTwWzDpjflJ0sU/logoKANTA.png")
  } else if (hash === '8sn3z6') {
    localStorage.setItem("s", "270")
    localStorage.setItem("logo", defaultLogo)
  } else if (hash === '99999') {
    localStorage.setItem("s", "999")
    localStorage.setItem("logo", defaultLogo)
  } else{
    validHash = false;
  }

  useEffect(() => {
    if (code && qrHost !== '') {
      window.location.href = `${qrHost}/lector-qr?code=${code}`;
    }else if (validHash) {
      navigate("/login?code="+code);
    }
  }, [])

  return (
    <div>
      {
        validHash ? 'Cargando...' : 'Link invalido'
      }
    </div>
  )
}
export default RedirectToAppV2
